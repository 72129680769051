.admin-brand-withcause {
  .upload-text {
    line-height: 30px;
    font-size: 22px;
    font-family: "Asap";
    font-weight: 500;
    color: #333;
    margin: 0px;
  }
  .top-section{
    display: flex;
    justify-content: space-between;
    align-items:center ;
    margin-bottom: 20px;
  }
 
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
  }
  .label-text {
    font-size: 20px;
    line-height: 25px;
    font-weight: normal;
  }
.choose-file{
  font-size: 20px;
  line-height: 25px;
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: 5px;
}
  .input-heading {
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }
.btn {
    display: flex;
    justify-content: space-evenly; 
    margin-top: 30px;  
}
  .checkout {
    font-size: 1.09375vw;
    line-height: 1.25vw;
    padding-bottom: 20px;
    padding-top: 20px;
  }

.heading {
    padding-bottom: 20px;
}
.darkbtn{
  background-color: rgb(106, 110, 106) !important;
    border: none;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 6px 22px !important ;


}
}


@media (min-width:204px) and (max-width:600px) {
.admin-brand-withcause {
.dropdown .select-field {
  background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
}
}
}

@media (min-width:1921px) and (max-width:6000px) {
  .admin-brand-withcause {
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/3% no-repeat;
    border-radius: 0.3vw;
    height: 2.1vw;
  }
  .upload-text{
    font-size: 1.5vw;
    line-height: 2vw;
  }
  .label-text, .choose-file{
    font-size: 1.04vw;
    line-height: 1.25vw;
    margin-bottom: 0.5vw;
  }
  .choose-file{
    margin-top: 1vw;
  }
  #myFile{
    font-size: 1vw;
    line-height: 1.5vw;
    width: auto;
    height: auto;
  }
  .darkbtn{
    border-radius: 0.5vw !important;
    padding: 0.4vw 0.8vw !important;
    font-size: 0.8vw !important;
    line-height: 1.4vw !important;
    height: auto;
  }
  }
}