.selectbox{
    .select-field{
        border-radius: 8px !important;
        padding: 8px 8px 8px 0px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 92%;
        font-family: 'Asap';
        border: 1px solid #d8d4cf;
        width: 219px !important;
        .MuiOutlinedInput-notchedOutline{
            // border:none !important;
        }
    }
    .select-field:hover .MuiOutlinedInput-notchedOutline{
        border:none !important;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border: none;
    }
    
    .selectboxwidth{
        width: 100%;
    }
    svg{
        display:none;
    }
    
}
.shippingselectbox{
    .select-field{
        border-radius: 8px !important;
        padding: 8px 8px 8px 0px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: 14px;
        font-family: "Asap";
        background-position: 97%;
        border: 1px solid #d8d4cf;
        width:100% !important;
        .MuiOutlinedInput-notchedOutline{
            // border:none !important;
        }
    }
    .select-field:hover .MuiOutlinedInput-notchedOutline{
        border:none !important;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border: none;
    }
    
    .selectboxwidth{
        width: 100%;
    }
    svg{
        display:none;
    }
}
@media (min-width:280px) and (max-width:600px) {
    .selectbox{
        .select-field{
            width:56vw !important;
        }
    }
}
@media (min-width:2000px) and (max-width:6000px) {
    .selectbox{
         .select-field {
        border-radius: 0.4vw !important;
        font-size: 0.8vw;
        padding: 0.4vw 0.4vw 0.4vw 0;
        height: 2.2vw;
        background-size: 0.7vw;
        width: 12vw !important;
        line-height: 1.2vw;
         }
    }
    .shippingselectbox{
        .select-field {
            border-radius: 0.4vw !important;
            font-size: 0.8vw;
            padding: 0.4vw 0.4vw 0.4vw 0;
            height: 2.2vw;
            background-size: 0.7vw;
            width: 100% !important;
             }
    }
}