.loginpage{
  padding-top: 120px;
    .login-full-container {
        margin: 0 auto;
        padding-bottom: 110px;
        text-align: center;
      }
    .icon-password{
      position: relative;
    }
      .visibility-icon {
        position: absolute;
        right: 37%;
        top: 1vw;
    }
    .input-error-message{
      width: 27.630208333%;
      margin: auto;
      text-align: left;
      margin-bottom: 44px;
      margin-top: 10px;
    }
    .input-field-loginside{
      margin-bottom: 0;
    }
    .icon-password{
      margin-bottom: 44px;
    }
    a:hover, a:active, a:focus {
      text-decoration: none;
  }
      .login-para {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0px;
        color: #2f2b23;
        opacity: 1;
        font-family: "Asap";
        margin-bottom: 91px !important;
        // max-width: 579px;
        margin: auto;
      }
      
      
      .forgot-password {
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #949d8c;
        opacity: 1;
        cursor: pointer;
      }
      
}
@media only screen and (max-width: 600px) {
.loginpage .visibility-icon {
  position: absolute;
  right: 7%;
  top: 3vw;
}
.loginpage .input-field-loginside {
  width: 90%;
}
.loginpage .input-error-message {
  width: 90%;
}
}
@media only screen and (min-width: 1921px) {
.loginpage{
 
    padding-top: 9vw;
    .input-field-loginside {
      height: 3vw;
  }
.login-para{
  font-size: 1.2vw;
  line-height: 1.4vw;
  margin-bottom: 4.7vw !important;
}
.forgot-password{
  font-size: 1.04vw;
    line-height: 1.25vw;
}
}
}