.pagination{
    margin-top: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    .row-page{
        font-size: 12px;
        line-height: 15px;
    }
}


@media (min-width:2000px) and (max-width:6000px) {
    .pagination {
        margin-top: 3vw;
        line-height: 1.2;
        font-size: 1vw;
        .MuiTablePagination-caption {
            font-size: 0.8vw;
            line-height: 1.2vw;
        }
        .MuiSelect-icon{
            top: -0.2vw;
        }
        .row-page{
            font-size: 1vw !important;
            line-height: 1.5vw !important;
        }
        .MuiPaginationItem-sizeSmall{
            height: 1.5vw;
            margin: 0 1px;
            padding: 0 7px;
            min-width: 1.5vw;
            border-radius: 13px;
            font-size: 1vw !important;
        }
       
    }
}