.ContactUs {
    .admin-contact {
        padding-top: 150px;
        padding-left: 7.2%;
        padding-right: 7.2%;
        background: #FFF;
    }

    .register-heading {
        text-align: left;
        letter-spacing: 0px;
        color: #2f2b23;
        font-family: 'GT-Super-Text-Regular';
        margin-bottom: 18px;
        margin-top: 15px;
        font-size: 72px;
    }

    .register-para {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #2f2b23;
        opacity: 1;
        font-family: "Asap", sans-serif;
        margin-right: 15.2%;
    }

    .contact-img {
        width: 100px;
        height: 144px;
    }

    .contact-us {
        margin-top: 49px;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
    }

    .email-text {
        text-align: left;
        font-size: 25px;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        font-family: 'GT-Super-Text-Regular';
        margin: 0px;
    }

    .input-field-outline {
        border: none;
        border-bottom: 2px solid #949d8c;
        width: 100%;
        text-align: left;
        font-size: 24px;
        letter-spacing: 0px;
        color: #949d8c;
        opacity: 1;
        font-family: "Asap", sans-serif;
        margin-bottom: 44px;
        background: transparent;
        border-radius: 0;
    }
    .input-textarea:focus-visible {
        // border: none;
        outline: none;
    }
    .input-textarea {
        border: none;
        border-bottom: 2px solid #949d8c;
        width: 100%;
        text-align: left;
        font-size: 24px;
        letter-spacing: 0px;
        color: #949d8c;
        opacity: 1;
        font-family: "Asap", sans-serif;
        margin-bottom: 44px;
        background: transparent;
        border-radius: 0;
        resize: auto;
    }
}

@media (min-width:280px) and (max-width: 600px) {
    .ContactUs {
        .register-heading {
            font-size: 44px;
        }
        .register-para{
            margin-right: 0;
        }
        .contact-img {
            width: 16vw;
            height: auto;
            margin-right: 12px
        }
        .email-text {
            text-align: left;
            font-size: 16px;
        }
        .contact-us-form {
            display: flex;
            flex-direction: column;
        }

        .input-field-outline,.input-textarea {
            font-size: 18px;
        }

    }

}


@media (min-width:600px) and (max-width: 920px) {
    .ContactUs {
        .register-heading {
            font-size: 38px;
        }
        .input-field-outline {
            border: none;
            border-bottom: 2px solid #949d8c;
        }

        .input-textarea {
            border: none;
            border-bottom: 2px solid #949d8c;
        }
        .contact-img {
            width: 8vw;
            height: auto;
            margin-right: 10px;
        }
        .email-text {
            text-align: left;
            font-size: 16px;
        }

    }

}

@media (min-width:921px) and (max-width: 1280px) {
    .ContactUs {
        .register-heading {
            font-size:50px;
        }
        .input-field-outline {
            border: none;
            border-bottom: 2px solid #949d8c;
        }

        .input-textarea {
            border: none;
            border-bottom: 2px solid #949d8c;
        }
        .contact-img {
            width: 8vw;
            height: auto;
            margin-right: 10px;
        }
        .email-text {
            text-align: left;
            font-size: 20px;
        }


    }

}

@media (min-width:2000px) and (max-width: 6000px) {
.ContactUs{
    .admin-contact {
        padding-top: 8vw;
        padding-left:0;
        padding-right: 0;
        background: #FFF;
    }
    .register-heading{
        font-size: 3.4vw;
    }
    .register-para{
        font-size: 1vw;
        line-height: 1.2vw;
    }
    .contact-img {
        width: 5vw;
        height: auto;
        margin-right: 0.5vw;
    }
    .email-text{
        font-size: 1.25vw;
        line-height: 2vw;
    }
    .input-textarea,.input-field-outline{
        font-size: 1.1vw;
        line-height: 1.5;
        margin-bottom: 2vw;
    }
}
}