.add-subsection {
  .upload-text {
    line-height: 30px;
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #333;
    margin: 0px;
    font-weight: normal;
  }

  .label-text {
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
    font-family: "Poppins", sans-serif;
  }

  .input-heading {
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }

  .product-img {
    height: 85px;
    width: 160px;
    margin: 10px 0;

    img {
      width: 100%;
      height: 100%;

    }
  }

    .pendingbtn {
      background: #367fa9 !important;
      background-color: #367fa9;
      color: #fff;
      border-radius: 3px !important;
      height: 38px;
      font-size: 15px !important;
      line-height: 18px !important;
      text-transform: capitalize;
      padding: 6px 12px !important;
      letter-spacing: 0px !important;
      font-weight: normal !important;
  }


  .checkout {
    padding-bottom: 20px;
    padding-top: 20px;
  }

}

@media (min-width:2000px) and (max-width:6000px) {
  .add-subsection{
    .input-heading{
      .upload-text{
      line-height: 1.4vw;
      font-size: 1.2vw;
      }
    
    .label-text {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .pendingbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      line-height: 1.4vw !important;
      height: auto;
  }
  .product-img {
    height: auto;
    width: 8vw;
    margin: 0.5vw 0;
}
.fileuploadsection .attach-btn {
  min-width: 45%;
  text-align: left;
  font-size: 1vw;
  line-height: 1.4vw;
}
.fileuploadsection .attach-btn label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FeaturedSection .fileuploadsection .attach-btn .plusicon {
  padding: 1px 0.65vw 6px;
  font-size: 1vw;
}
  }
  
}
}


