.swal2-popup{
    padding:25px !important;
    width: 28em !important;
}
.swal2-title{
    font-family: "Asap" !important;
    font-style: normal !important;
    font-size: 18px !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    color: #2f2b23 !important;
    margin-bottom: 16px !important;
    padding:0px !important;
    text-align: left !important;
}
.swal2-actions{
    margin-top:0 !important;
    width: 100%  !important;
    justify-content: flex-start  !important;
    flex-direction: row-reverse !important;

}
.swal2-html-container{
    font-family: "Asap"  !important;
    font-style: normal  !important;
    font-size: 16px  !important;
    padding:0px  !important;
    font-weight: normal  !important;
    color: #2f2b23  !important;
    text-align: left  !important;
    margin: 0  !important;
    margin-bottom: 16px  !important;
}
.swal2-cancel{
    font-weight: 600 !important;
    font-family: 'Asap' !important;
    font-style: normal !important;
    font-size: 14px !important;
    border: none !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;
    background-color: #d3cec0 !important;
    color: #2f2b23 !important;
    border: none !important;
    margin-right: 8px !important;
    min-width: 65px;
    min-height: 38px;
}
.swal2-confirm {
    background-color: #949d8c !important;
    border-radius: 8px !important;
  
    padding: 8px 16px !important;
    font-family: 'Asap' !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #ffffff !important;
    border: none !important;
    min-width: 65px;
    min-height: 38px;
  }
  .swal2-styled.swal2-confirm:focus{
    box-shadow:none;
    
  }