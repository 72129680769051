.seller-dashboard {

    margin-top: 44px;
    .main-heading40 {
        font-weight: 500;
        line-height: 27.5px;
        font-size: 24px
    }
    .heading-row {
        justify-content: space-between;
        display: flex;
        margin-bottom: 44px;
    }
  
    a:hover, a:active, a:focus {
        text-decoration: none;
    }

    .graph-section {
        padding-bottom: 102px;
    }

    .store-img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        object-fit: cover;
    }

    .store-heading {
        font-family: "Asap";
        font-style: "semi-bold";
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        color: #2f2b23;
        margin-bottom: 8px;
    }
.price{
    font-family: "Asap";
        font-style: "semi-bold";
        font-size: 24px;
        line-height: 27px;
        font-weight: 500;
        color: #2f2b23;
        margin-bottom: 8px;
}
    .account-text {
        font-family: "Asap";
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: #2f2b23;
        margin: 0px;
        margin-bottom: 20px !important;
        margin-top: 16px !important;
    }

    .grid-box {
        margin: 0px;
        min-height: 110px;
        border: 1px solid #d8d4cf;
        margin-left: -1px;
        text-align: center;
    }

    .store-row {
        margin-bottom: 30px;
    }

    .store-row .MuiGrid-root {
        margin-top: 0px !important;
        width: calc(100% + 0px) !important;
        margin-left: 0 !important;
        padding: 0px 0px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .store-row .MuiGrid-root.MuiGrid-item {
        padding: 16px 0px !important;
    }

    .completed-icon {
        background: #949d8c;
        border-radius: 50%;
        padding: 8px;
        height: 34.5px;
        width: 36px;
        margin-left: -8px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
    }

    .box-title {
        font-family: "Asap";
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: #2f2b23;
        margin: 0px;
        margin-bottom: 10px !important;
        margin-top: 4px !important;
        // text-decoration: underline;
    }
    .textunderline{
        text-decoration: underline;
    }

    .grid-box:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .grid-box:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .grid-box-n {
        border: 1px solid #d8d4cf;
        padding: 15px;
        border-radius: 8px;
        min-height: 167px;
        
    }
.graph-n{
    min-height: auto;
}

.graph-bar{
    min-height: 100%;
}
    .text-view {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box-title {
            line-height: 0px;
            margin-bottom: 0px !important;
            margin-top: 0px !important;
        }
    }

    .text-view-repat {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid rgb(216, 212, 207);

        .box-title {
            margin-left: 8px !important;
        }

        .store-heading {
            margin-bottom: 0px;
        }
    }
.outofstock-text{
    border-bottom: none;
}
.sidepadding{
    padding-right: 9px;
}
.marginbott{
    margin-bottom: 21px;
}
    .grid-box-bank {
        background-color: #e8e5e3;
        border: 1px solid #d8d4cf;
        padding: 15px;
        border-radius: 8px;

        .darkbtn{
            text-transform: inherit !important;
            font-weight: 500 !important;
        }
    }

    .bank-heading {
        font-weight: normal;
        font-family: 'Asap';
        font-style: italic;
        font-size: 22px;
        line-height: 25px;
        color: #2f2b23;
        margin-bottom: 8px;
    }

    .bankpara {
        font-weight: normal;
        font-family: "Asap";
        font-style: normal;
        font-size: 16px;
        color: #757061;
        margin-bottom: 17px;

    }
}

@media (min-width:280px) and (max-width:600px) {
    .seller-dashboard {
        .chart-text{
            padding: 0 !important;
        }
        .grid-box:first-child,.grid-box:last-child{
            border-radius: 0 !important;
        }
    }
}
@media (min-width:768px) and (max-width:919px) {
    .seller-dashboard {
        .grid-box:first-child,.grid-box:last-child{
            border-radius: 0 !important;
        }
    }
}
@media (min-width:920px) and (max-width:1023px) {
    .seller-dashboard {
       .all-container-27inch{
        padding-left:0px !important;
       }
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .seller-dashboard {
        .main-heading40 {
            font-size: 1.5vw;
            line-height: 1.7;
        }
        .grid-box-n,.grid-box-bank{
            padding:0.8vw;
        }
      .graph-n {
            min-height: 22vw;
        }
        .store-img {
            width: 4vw;
            height: 4vw;
        }

        .store-heading {
            font-size: 1.1vw;
            line-height: 1.5vw;
            margin-bottom: 0.6vw;
        }

        .bankpara {
            font-size: 1.1vw;
            line-height: 1.5vw;
            margin-bottom: 0.6vw;
        }

        .bankdetailsimg {
            width: 100;
        }

        .bank-heading {
            font-size: 1.4vw;
            line-height: 1.5vw;
            margin-bottom: 0.6vw;
        }

        .box-title {
            font-size: 1vw;
            line-height: 1.2vw;
            margin-bottom: 1.2vw !important;
            margin-top: 0.5vw !important;
        }

        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
        }

        .completed-icon {
            padding: 0.4vw;
            height: 2vw;
            width: 2.1vw;
        }

        .dashboardchart .k-card text {
            fill: #99abb4;
            font-size: 0.75vw !important;
        }
    }
}

@media (min-width:4000px) and (max-width:6000px) {
    .seller-dashboard {
        .dashboardchart .k-card text {
            font-size: 0.55vw !important;
        }
    }

}