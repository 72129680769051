.exclusiveblogs{
    .blogbgimg{
        width: 100%;
    }
    .blog-row {
        background-color: #e4e1d9;
        text-align: center;
        padding-bottom: 35px;

      }
      .email-img-row {
        margin-bottom: -31px;
    }
      .blog-container {
        width: 44.68%;
        margin: auto;
        min-width: 300px;
      }
      
      .blog-text {
        text-align: center;
        font-size: 28px;
        letter-spacing: 0px;
        color: #2f2b23;
        font-family:'GT-Super-Text-Regular';
        margin-bottom: 44px;
      }
      .input-field-loginside{
        background: transparent;
        text-align: center;
      }
      .goethical-text{
        color: #757061;
      }
}

@media only screen and (min-width: 1921px) {
  .exclusiveblogs{
      .email-img-row {
        margin-bottom: -40px;
    }
    .blog-text{
      font-size: 1.5vw;
      line-height: 2vw;
    }
    .input-field-loginside {
      font-size: 1.5vw !important;
      line-height: 1.9vw !important;
    }
    .blackbtn{
      font-size: 1.2vw !important;
    }
  }
}

@media only screen and (min-width: 3000px) {
  .exclusiveblogs{
      .email-img-row {
        margin-bottom: -60px;
    }
  }
}