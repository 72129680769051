.progressbar {
  padding-top: 85px;
}
.progressbar-progress{
  height: 2px !important;
  background-color: #7F6A86 !important;

}
.progressbar-container {
  background-color: #E8E5E3 !important;
}

@media only screen and (min-width: 1921px) {
  .progressbar {
    padding-top: 5vw;
  }
}
