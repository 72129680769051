.card-container {
  background-color: #ffff;
  height: auto;
  border-top: 3px solid #3c8dbc;
  padding: 24px;

  .email {
    margin: 0px 0px 9px 0px;
    font-size: 19px;
    color: #333;
    font-weight: 700;
    font-family: 'Asap';

  }

  .mail-text {
    margin: 0px 0px 10px 0px;
    font-size: 17px;
    color: #333;
    font-family: 'Asap';
    font-weight: 300;
  }

  .darkbtn {
    background-color: #f4f4f4 !important;
    border-radius: 3px !important;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
    color: black !important;
  }

  // .order-item{
  //     font-size: 24px;
  // font-weight: 500;
  // color: #444;
  // margin: 0px;
  // width: auto;
  // padding: 0px;
  // }
}

.product-item {
  background-color: #ffff;
  height: auto;
  border-top: 3px solid #3c8dbc;


  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 24px;
  }
tr{
  color: #666;
  font-size: 15px;
  width: 100%;
  white-space: initial;
  padding-right: 5px;

}
.icon-visibility {
  height: 29px;
  width: 30px;
  box-shadow: 0px 1px 0px 0px #ccc !important;
  border-radius: 4px;
  color: #302b2b;
  text-align: center;
  background-color: #ccc;
  margin-top: 5px;
  margin-bottom: 4px;
  cursor: pointer;
  padding: 4px;
}
.icon-visibility:hover {
  background-color: #17a2b8;
  color: #ffffff;
}
.price{
  color: #17a2b8;;
}
  td,
  th {
    // border: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  //   tr:nth-child(even) {
  //     background-color: #dddddd;
  //   }
  .table-heading {
    color: #060707;
    font-size: 16px;
    font-family: 'Asap';
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .product-img {
    height: 80px;
    padding: 8px 0px;
  }


}

.tab-section-container {
  padding: 15px;
  // background-color: rgb(250, 249, 249);

  .detail-heading {
    font-size: 28px;
    font-weight: 500;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
    margin-bottom: 20px;
  }

  .css-1gsv261 {
    border-bottom: none;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #5E6657;
    font-family: "Asap";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    opacity: 1;
    font-weight: 500;
  }

  .MuiButtonBase-root.MuiTab-root {
    color: #5E6657;
    font-family: "Asap";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    opacity: 0.5;
    font-weight: 500;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: #5E6657;
  }

}

@media (min-width:280px) and (max-width:600px) {
  .tab-section-container {
    padding: 15px 0;
    .paddingremove .MuiBox-root {
      padding: 24px 0;
  } .product-item{
      overflow: auto;
    }
   
  }
}
@media (min-width:2000px) and (max-width:6000px) {
  .card-container {
  
    .email {
      margin: 0px 0px 0.4vw 0px;
      font-size: 1vw;
    }

    .mail-text {
      margin: 0px 0px 0.4vw 0px;
      font-size: 0.8vw;
    }

    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

  

  .MuiTab-textColorPrimary.Mui-selected,
  .MuiTab-textColorPrimary {
    font-size: 1.1vw !important;
    line-height: 1.4vw !important;
    font-weight: 600 !important;
    padding: 0.5vw !important;
    max-width: 12vw !important;
    width: 12vw !important;

  }

  .MuiTabs-flexContainer {
    padding: 0.5vw 0;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #060707 !important;
  }

  .product-item {
    .table-heading {}

    td,
    th {
      padding: 8px;
      color: #060707;
      font-size: 0.8vw;
      line-height: 1.5vw;
    }

    .product-img {
      height: 4vw;
    }

    .MuiSvgIcon-root {
      width: 1.5vw !important;
      height: 1.5vw !important;
    }
  }
}
}
