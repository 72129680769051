#root {
  background: #fff;
}

.createaccount {
  max-width: 454px;
  margin: auto;
  width: 50%;
  padding-top: 62px;
  padding-bottom: 287px;

  .text-heading {
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: 'Asap';
    font-style: italic;
    margin-bottom: 10px;
  }

  .Mui-checked,
  .MuiCheckbox-indeterminate {
    color: #757061 !important;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    color: #a6a4a0 !important;
  }

  .MuiButtonBase-root.MuiCheckbox-root:hover {
    background-color: transparent;
  }

  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }

  .visibility-icon {
    position: absolute;
    right: 34%;
    padding-top: 14px;
  }

  .input-field-outline {
    position: relative;
  }

  .text-para {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #757061;
    opacity: 1;
    font-family: 'Asap';
    margin-bottom: 24px;
    font-style: normal;
    font-weight: normal;
  }

  .label-name {
    color: #2f2b23;
    font-size: 16px;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: normal;
    opacity: 1;
    font-family: 'Asap';
    // margin-bottom: 4px;
  }

  .mrgbottom {
    margin-bottom: 20px;
  }

  .css-ahj2mt-MuiTypography-root {
    letter-spacing: 0px;
    opacity: 1;
    font-family: 'Asap';
    margin-bottom: 5px;
    margin-top: 2px;
    // margin-left: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #2f2b23;
  }

  .btn-end {
    text-align: end;
    margin-top: 20px;
  }

  .darkbtn {
    min-height: 45px !important;
    min-width: 102px !important;
    margin-top: 32px;
    font-size: 16px !important;
    margin-bottom: 50px;
    box-shadow: none !important;
  }

  .disabledBtn {
    min-height: 45px !important;
    min-width: 102px !important;
    margin-top: 32px;
    font-size: 16px !important;
    margin-bottom: 50px;
    box-shadow: none !important;
  }

  .lightbtn {
    min-width: 7vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .createaccount {
    width: 80%;

    .visibility-icon {
      right: 14%;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1590px) {
  .createaccount {
    .visibility-icon {
      right: 36%;
      padding-top: 14px;
    }
  }
}

@media (min-width: 1591px) and (max-width: 1920px) {
  .createaccount {
    .visibility-icon {
      right: 38.5%;
      padding-top: 14px;
    }
  }
}

@media only screen and (min-width: 1921px) {
  .createaccount {
    max-width: 25.64vw;
    padding-top: 5vw;

    .visibility-icon {
      right: 38.5%;
    }

    .mrgbottom {
      margin-bottom: 1.55vw;
    }

    .text-heading {
      font-size: 1.65vw;
      line-height: 1.66vw;
      margin-bottom: 0.866vw;
    }

    .text-para {
      font-size: 1.1vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }

    .label-name {
      font-size: 1vw;
      line-height: 1.4vw;
      margin-bottom: 0.5vw;
    }

    .MuiTypography-root {
      font-size: 1vw;
      line-height: 1.4vw;
    }

    .darkbtn {
      min-height: 3vw !important;
      min-width: 8vw !important;
    }

    .btn-end {
      margin-top: 1.25vw;
    }

    .darkbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .disabledBtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .lightbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
  }
}
