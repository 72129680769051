.editimages{
    .upload-text{
        font-size: 18px;
        line-height: 25px;
        font-family: "Asap";
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }
 .product{
    height: auto;
    width: 100%;

    img{
        width: 100%;
        height: 100%;
    }
 }


 .label{
    font-size: 16px;
    line-height: 25px;
    font-family: "Asap";
    font-weight: normal;
    margin-top: 40px;
    color: #333;
 }
 .input-field-outline {
    width: 225px;
 }
    .btn-section-refund{
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;

        .darkbtn, .lightbtn{
            text-transform: capitalize;
        }
        .darkbtn{
            margin-right: 10px;
        }
       
    }
    .dropdown {
        margin-bottom: 20px;
    }
}
@media (min-width:2000px) and (max-width:6000px) {
    .editimages{
        .upload-text {
            font-size: 1vw;
            line-height: 1.5;
        }
        .cropper-container{
            height: 500px !important;
        }
        .cropper-crop-box{
            height: 350.6px !important;
           width: 700px !important;
         }
        .label {
            font-size: 0.8vw;
            line-height: 1.2vw;
        }
      
        .input-field-outline {
            width: 13vw;
        }
        .darkbtn,.lightbtn{
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
        }
        .product {
            height: 18vw;
            width: 100%;
        }
    }
}

@media (min-width:4000px) and (max-width:6000px) {
    .editimages{
.cropper-container{
    height: 750px !important;
}
.cropper-crop-box{
    height: 500.6px !important;
    width: 1031.172px !important;
 }
}
}