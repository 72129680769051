.uploadimages{
   .uploadpara{
    font-family: "Asap";
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    margin: 0px 0px 24px 0px;
    color: #2f2b23;
   } 
   .darkbtn{
    text-transform: initial !important;
    background-color: #C4C9C0 !important;
    font-weight: 500 !important;
   }
   
   .img-container-upload {
    display: inline-block;
    height: auto;
    padding: 5px 6px;
    width: 50%;

    .productscard{
        position: relative;
        margin-top: 8px;
         .img-row{
            height: 99px;
         }
    }
    .productscard .product-text{
        width: 170px;
        padding-right: 20px;
    }
    .close-icon {
        position: absolute;
        top: 6px;
        right: 10px;
        color: #efe9e9;
        cursor: pointer;
    }
}
u {
    cursor: pointer;
    padding-right: 6px;
}

   .drop-down{
    margin-bottom: 24px;
    border: 1px dashed #ccc;
    border-radius: 8px;
}
.file-label{
text-align: center;
margin: 16px 0px 0px 0px;
}
.upload-img{
    height: 35px;
    width: 35px;
    margin-top: 20px;
}
.select-image-text {
font-family: "Asap";
font-weight: normal;
font-style: normal;
font-size: 16px;
margin: 16px 55px 25px 73px;
color: #2f2b23;
text-align: center;
padding: 0px 30px;
}
.image-text{
    font-family: "Asap";
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    color: #2f2b23;
    text-align: left;
    margin: 0;
}
.btn-section{
    display: flex;
    justify-content: end;margin-top: 10px;
}

.file-section {
    position: relative;
    height: 138px;
    width: 138px;
  }
   .cover-img {
    position: relative;
    border: 1px dashed #ccc;
    margin: 16px 0px;
    border-radius: 8px;
    width: 412.5px;
    height: 160px;

    .bg-img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  
  }

}

@media (min-width:280px) and (max-width:600px) {
    .uploadimages{
        .select-image-text{
            margin:0px;
        }
        .img-container-upload .productscard .product-text {
            width: 100px;
            padding-right: 10px;
        }
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .uploadimages{
        .sub-heading{
            font-size: 1vw;
            line-height: 1.2vw;
        }
        .productscard .img-row{
            height: 10.91vw !important;
        }
        .productscard .product-text{
            width: 13vw !important;
            font-size: 0.8vw;
            line-height: 1.2vw;
        }
        .image-text{
            font-size: 0.8vw;
            line-height: 1.2vw;
        }
        .uploadpara{
            font-size: 0.8vw;
            line-height: 1.2vw;
        }
        .drop-down {
            margin-bottom: 1.2vw;
            border: 1px dashed #ccc;
            border-radius: 0.4vw;
        }
        .file-label {
            margin: 0.8vw 0px 0px 0px;
        }
        .upload-img {
            height: 1.8vw;
            width: 1.8vw;
            margin-top: 1.2vw;
        }
        .select-image-text{
            font-size: 0.8vw;
            margin: 0.8vw 4vw 1.2vw 5vw;
        }
        .lightbtn,
        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
            
        }

    }
    .MuiDialogContent-root{
        padding:1.2vw 1.4vw !important;
    }
}