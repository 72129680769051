.financialreports {
    background-color: rgb(250, 249, 249);
    padding-bottom: 50px;
    .financialreportscontainer {
        padding-right: 5%;
    }
    a:hover, a:active, a:focus {
        text-decoration: none;
    }
    .complete-section {
        margin-top: 32px;
    }
table{
    background: #faf9f9;
    th{
        padding: 8px 16px !important; 
    }
}
.datefilter-btn{
    .lightbtn{
        color: #A6A4A0 !important;
        background-color: #E8E5DE !important;
    }
}
    .financial-heading {
        font-family: "Asap";
        font-style: normal;
        font-size: 26px;
        line-height: 30px;
        font-weight: 500;
        color: #2f2b23;
        margin-bottom: 24px;
    }

    .heading-row {
        justify-content: space-between;
        display: flex;
        margin-bottom: 44px;
    }
    
    .store-img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
    }

    .store-heading {
        font-family: "Asap";
        font-style: "semi-bold";
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        color: #2f2b23;
        margin-bottom: 8px;
    }

    .account-text {
        font-family: "Asap";
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: #2f2b23;
        margin: 0px;
        margin-bottom: 20px !important;
        margin-top: 16px !important;
    }

    .grid-box {
        margin: 0px;
        min-height: 110px;
        border: 1px solid #d8d4cf;
        margin-left: -1px;
        text-align: center;
    }

    .store-row {
        margin-bottom: 32px;
    }

    .store-row .MuiGrid-root {
        margin-top: 0px !important;
        width: calc(100% + 0px) !important;
        margin-left: 0 !important;
        padding: 0px 0px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .store-row .MuiGrid-root.MuiGrid-item {
        padding: 15px 0px !important;
    }

    .completed-icon {
        background: #949d8c;
        border-radius: 50%;
        padding: 8px;
        height: 34.5px;
        width: 36px;
        margin-left: -8px;
    }

    .box-title {
        font-family: "Asap";
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: #2f2b23;
        margin: 0px;
        margin-bottom: 20px !important;
        margin-top: 6px !important;
    }
    .marginbott{
        margin-bottom: 0px !important;
    }
    .search-title{
        margin-bottom: 4px !important;
    }
    .grid-box:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .grid-box:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .datefilter-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .lightbtn {
            margin-left: 20px !important;
            margin-bottom: 5px !important;
        }
        .datepickerpart{
            display: flex;
        }
    }

    .css-q8hpuo-MuiFormControl-root {
        min-width: 250px !important;
    }

    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        border: 1px solid rgba(224, 224, 224, 1);
        box-shadow: none !important
    }

    .css-1azl6jz-MuiTableCell-root,
    .css-1ygcj2i-MuiTableCell-root {
        font-family: "Asap" !important;
        font-style: normal !important;
        font-size: 14px !important;
        font-weight: normal !important;
        color: #2f2b23 !important;
        border-bottom: 2px solid rgba(224, 224, 224, 1);
    }

    .tabledata {
        margin-top: 15px;
    }

    .empty-table {
        text-align: center;
        display: none;
    }
}
@media (min-width:280px) and (max-width:600px) {
    .financialreports{
        .financialreportscontainer{
            padding-right: 0;
        }
        .datefilter-btn .datepickerpart {
     display: block;
}
.exportbtn{
    margin-top:15px;
    text-align: right;
}
        .datefilter-btn{
            display: block;
            text-align: center;
            .hs0.un8.tkf{
                display: block;
            }
            .xuA{
                margin-bottom: 14px;
            }
            .lightbtn{
                width: 100%;
                margin: 0 !important;
            }
        }
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .financialreports {
        .financial-heading {
            font-size: 1.5vw;
            line-height: 1.7;
        }

        .store-heading {
            font-size: 1.2vw;
            line-height: 1.5;
        }

        .lightbtn,
        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
        }

        .box-title {
            font-size: 0.8vw;
            line-height: 1.1vw;
            margin-bottom: 1.2vw !important;
            margin-top: 0.5vw !important;
        }

        .tabledata {

            table th,
            table td {
                font-size: 0.85vw !important;
                line-height: 1.4vw;
                padding: 0.8vw;
                border-bottom: 2px solid #e0e0e0;
            }
        }

        .datefilter-btn {
            .xuA {
                margin-left: 0.2vw;
                margin-right: 0.2vw;
                width: 9vw;
            }
            .react-datepicker__input-container input {
                padding-right: 1.6vw;
                height: 2.2vw;
                font-size: 0.8vw;
            }
            svg{
                font-size: 1.2vw;
                width: 1vw;
                height: 1vw;
            }
            .react-datepicker{
                zoom:2;
            }
            .react-datepicker__navigation { svg{
                font-size: 1vw;
                width: 0.5vw;
                height: 0.5vw;
            } 

            }
        }
    }
}