.productskeleton{
    .maintitle{
        width: 35%;
        height: 5vw;
    }
    .sectiontitle{
        width:70%;
    }
    .search{
        width: 30%;
        height: 3vw;
        border-radius: 0.5vw;
    }
    .btnsection{
        width: 75%;
        display: inherit;
        margin-bottom: 20px;
        align-items: center;
    }
    .btn1{
        width: 40%;
        margin: 1%;
        height: 3vw;
        border-radius: 0.5vw;
    }
    .btn{
        width: 20%;
        margin: 1%;
        height: 3vw;
    border-radius: 0.5vw;
    }
    .searchbtnsection{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .boxsection{
        width: 100%;
    }
    .productimgbox{
        display: inline-block;
        width:24%;
        margin: 0.5%;
        margin-bottom: 20px;
    }
    .gboxes{
        height: 12vw;
        border-radius: 0.5vw;
    }
    .producttname {
        width: 70%;
    }
    .price {
        width: 40%;
    }
}