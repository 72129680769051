
body {
  margin: 0;
  font-family: "ASAP";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ASAP";
  src: local("ASAP"),
   url('./assets/font/asap.bold.ttf') format("ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT-Super-Text-Regular";
  src: local("GT-Super-Text-Regular"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Regular.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Bold";
  src: local("GT-Super-Text-Bold"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Bold.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Medium";
  src: local("GT-Super-Text-Medium"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Medium.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Black";
  src: local("GT-Super-Text-Black"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Black.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
