.codeinputbox{
    .code-input{
        width: 100%;
    padding: 0px 8px;
    height: 38px;
    margin-bottom: 8px;
    min-width: 120px;
    border: 1px solid #d8d4cf;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
    margin-top: 4px;
    font-size: 14px;
    line-height: 130%;
    font-family: 'Asap';
    }
    .inputwithicon{
        display: flex;
    }
    .icon-row{
        background-color: #d3cec0;
    height: 37px;
    border-radius: 0px 8px 8px 0px;
    margin-top: 4px;
    width: 42px;
    border: none;
    .MuiSvgIcon-root{
        cursor: pointer;
    }
    }
}
input.code-input:focus-visible {
    outline: none;
}
.input-field-loginside {
    width: 27.630208333%;
    border: none;
    border-bottom: 2px solid #949d8c;
    text-align: left;
    font-size: 24px;
    letter-spacing: 0px;
    color: #949d8c;
    opacity: 1;
    font-family: "Asap";
    margin-bottom: 44px;
    min-width: 300px;
    padding-bottom: 15px;
   
}
input:-internal-autofill-selected {
    background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    color: #949d8c;
}
input.input-field-loginside:focus-visible {
    outline: none;
}
input:-webkit-autofill{
    -webkit-text-fill-color: #949d8c !important;
}
.input-field-outline {
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: transparent;
    height: 42px;
    margin-top: 4px;
    padding-left: 8px;
    font-size: 16px;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: normal;
    opacity: 1;
    font-family: "Asap";
    color: #2f2b23;
    background-color: #FFF;
}

.input-field-outline:focus-visible {
    outline: none;
}

.input-textarea {
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: transparent;
    margin-top: 4px;
    padding-left: 8px;
    font-size: 16px;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: normal;
    opacity: 1;
    font-family: "Asap";
    color: #2f2b23;
    height: 83px;
    margin-bottom: 20px;
    resize: none;
    background-color: #FFF;

}

.input-textarea:focus-visible {
    outline: none;
}

//dropdown

.dropdown{
    .select-field{
        height: 42px;
        border-radius: 8px;
        border: 1px solid #ccc;
        background-color: #FFFFFF;
        color: #2f2b23;
        font-size: 16px;
        line-height: 18px;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 92%;
        padding-left: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../../assets/images/sellerSide/dropdown.webp) 99%/16px no-repeat;
        background-position-y: inherit;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        .MuiOutlinedInput-notchedOutline{
            border:none !important;
        }
        .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-bpeome-MuiSvgIcon-root-MuiSelect-icon, .MuiSvgIcon-root{
            display: none;
        }
    }
    .select-field:hover .MuiOutlinedInput-notchedOutline{
        border:none !important;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border: none;
    }
    
    .dropdownwidth{
        width: 100%;
    }
}

@media (min-width:601px) and (max-width:920px){
    
    .dropdown .select-field {
            background: url(../../assets/images/sellerSide/dropdown.webp) 99%/5% no-repeat;
        }
  
}
@media only screen and (min-width: 1921px) {
    .dropdown{
        .select-field{
            height: 2vw !important;
            font-size: 0.8vw;
            line-height: 1.2vw !important;
        }
    }
    .input-field-loginside {
        font-size: 1.25vw;
        line-height: 1.35vw;
        margin-bottom: 2.5vw;

    }

    .input-field-outline,
    .input-textarea {
        font-size: 1.04vw;
        line-height: 1.25vw;
        margin-top: 0.5vw;
        padding-left: 0.5vw;
        border-radius: 0.5vw;
    }

    .input-field-outline {
        height: 2.28vw;
        border-radius: 0.5vw;
    }

    .input-textarea {
        height: 5vw;
        border-radius: 0.5vw;
        padding-top: 0.5vw;
    }
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  display: none;
}