.sellerorder {
    padding-bottom: 60px;
    background-color: rgb(250, 249, 249);

    .MuiTablePagination-actions{
        display: flex;
    }
    .dropdown .select-field {
        background: url(../../assets/images/sellerSide/dropdown.webp) 95%/7% no-repeat;
        width: 160px !important;
        height: 38px;
        background-color: #FFF;
    }

    table {
        background: #faf9f9;
        p{
            margin: 0 0 5px;
        }
       
    }
  
    .date {
        text-transform: lowercase;
    }
    .lightbtn {
        font-weight: 500 !important;
        text-transform: inherit !important;
        margin-right: 0px !important;
   

    }

    .search-input {
        margin-right: 8px !important;
        height: 38px;
        background-color: #FFF;
        width: 180px !important;
    }

    .MuiOutlinedInput-input {
        padding: 12.5px 14px 8.5px 0px
    }

    .main-heading40 {
        margin-bottom: 38px;
        margin-top: 32px;
    }

    .sellerordercontainer {
        padding-right: 9%;
    }

    .MuiButtonBase-root {
        display: block !important;
    }

    .css-96uuyl {
        margin-left: 0px;
    }

    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }

    .order-row {
        display: flex;
        margin-bottom: 16px;
    }

    .css-q8hpuo-MuiFormControl-root {
        min-width: 250px !important;
    }

    .MuiTableCell-body {
        padding: 16px 13px;
    }

    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        border: 1px solid rgba(224, 224, 224, 1);
        box-shadow: none !important
    }

    .css-13xy2my {
        border: 1px solid rgba(224, 224, 224, 1);
        box-shadow: none !important
    }

    .css-1q1u3t4-MuiTableRow-root,
    .css-1azl6jz-MuiTableCell-root,
    .css-1ygcj2i-MuiTableCell-root,
    .css-1bigob2,
    .css-1sgp6db {
        font-family: "Asap" !important;
        font-style: normal !important;
        font-size: 14px !important;
        font-weight: normal !important;
        color: #2f2b23 !important;
        padding: 8px 16px;
        border-bottom: 2px solid rgba(224, 224, 224, 1);
    }

}

table .lightbtn {
    padding: 6px 10px !important;
}

@media (min-width:264px) and (max-width:600px) {
    .sellerorder {
     .search-input{
        margin-bottom: 10px;
     }
     .dropdown{
        .select-field{
            width: 178px !important;
        }
     }
    }
}
@media (min-width:2000px) and (max-width:6000px) {
    .sellerorder {
        .main-heading40 {
            font-size: 1.5vw;
            line-height: 1.7;
        }
        .lightbtn{
            font-size: 0.8vw !important;
        }
        .search-input {
            height: 2.1vw;
            width: 12vw !important;
        }    
        .dropdown .select-field {
            height: 2.1vw;
           width: 12vw !important;
        }    
        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
        }

        .sellerordercontainer {

            table th,
            table td {
                font-size: 0.85vw !important;
                line-height: 1.4vw;
                padding: 0.8vw;
                border-bottom: 2px solid #e0e0e0;
            }

        }

    }
}