.financalreportskeleton{
    table{
        width:100%;
    }
    td{
        padding:1vw;
    }
    .ordernumber,.payoutdate{
        width:20%;
    }
    .amount,.totalfee,.netpayout,.refund{
        width:10%;
        text-align: center;
        span{
            width:40%;
            margin: 0 auto;
        }
    }
    .acionbtn{
        height:3vw;
        border-radius: 1vw;
    }
}