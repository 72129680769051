.welcomescreen {
    max-width: 454px;
    margin: auto;
    width: 50%;
    padding-top: 50px;

    .text-heading {
        text-align: center;
        margin-bottom: 16px;
        font-family: 'GT-Super-Text-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 26.1994px;
        line-height: 30px;
        text-align: center;

        color: #000000;
    }

    .text-para {
        text-align: center;
        font-size: 18px;
        letter-spacing: 0px;
        color: #757061;
        opacity: 1;
        font-family: "Asap";
        margin-bottom: 32px;
        font-style: normal;
        font-weight: normal;
        line-height: 30px;
        text-align: center;
        color: #757061;
    }

    .row-tab {
        border-radius: 40px;
        width: 59px;
        align-items: center;
        background-color: #ccc;
        width: 49px;
        height: 49px;
        margin-right: 18px;
    }

    .details-text {
        letter-spacing: 0px;
        opacity: 1;
        font-family: "Asap";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        color: #2F2B23;

    }

    .img-section {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
.btn-section{
    text-align: end;
    margin-top: 35px;
}
}
@media only screen and (max-width: 600px) {
    .welcomescreen {
        width: 80%;
    }
}

@media only screen and (min-width: 1921px) {
    .welcomescreen {
        width: 80%;
        max-width: 25.64vw;
        padding-top: 4vw;
    .text-heading {
        font-size: 1.5vw;
        line-height: 1.66vw;
        margin-bottom: 0.866vw;
    }
    
    .text-para {
        font-size: 0.937vw;
        line-height: 1.25vw;
        margin-bottom: 2.25vw;
    }
    .img-section{
        margin-bottom: 1.35vw;
    }
    .row-tab {
        border-radius: 2.5vw;
        width: 59px;
        width: 2vw;
        height: 2vw;
        margin-right: 0.937vw;
    }
    .icon-img{
        width: 100%;
    }
    .details-text{
        font-size: 0.937vw;
        line-height: 1.25vw;
    }
    .btn-section {
        margin-top: 3.35vw;
        margin-bottom: 3.35vw;
    }
    }
    }