.prosellerregistration {
    .input-field-loginside {
        width: 100%;
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 26px;
        height: 47px;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #949d8c;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #949d8c;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #949d8c;
      }
    .dropdown .select-field {
        padding-left: 0px;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 0px 14px 16.5px 14px
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        border-radius: 0px;
    }

    .darkbtn {
        border-radius: 35px !important;
        padding: 10px 38px !important;
        font-size: 18px !important;
        min-width: 153px !important;
    }

    .selectbox .select-field {
        font-size: 24px;
        line-height: 26px;
        color: #949d8c;
        background-size: 14px;
        background-position: 92%;
        border-bottom: 2px solid #949d8c !important;
        border: none;
        border-radius: 0px !important;
        width: 100% !important;


        .MuiOutlinedInput-notchedOutline {
            border: none !important;
            border-radius: 0px;
        }


    }

    .setupselectbox {
        .MuiSelect-select.MuiSelect-outlined {
            font-size: 20px;
            font-style: normal;
            color: #949d8c;
            border: none;
            border-bottom: 2px solid #949d8c;
            text-align: left;
            font-family: "Asap";
            padding-left: 0;
            padding-bottom: 15px;
            margin-bottom: 10px;
            border-radius: 0;
        }
    }

    .MuiGrid-container {
        margin-top: 0 !important;
    }

    .input-error-message {
        font-size: 12px;
        color: #fc4b6c !important;
        display: block;
    }

    .input-field-loginside {
        min-width: 100% !important;
    }

    .select-field:hover .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .btn-section {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .checkbox-trader {
        margin-right: 31px;
        width: 33px;
        height: 33px;
        border: 2px solid #949d8c;
        opacity: 1;
    }

    .checkbox-section {
        display: flex;
        align-items: center;
    }

    .label-trader {
        text-align: left;
        font-size: 21px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #949d8c;
        font-family: "Asap";
        opacity: 1;
        margin: 0px;
    }
}

.MuiList-root li.MuiMenuItem-root{
    white-space: break-spaces ;
}
.registertoday {

    .your-company-section {
        margin-top: 60px;
    }


}

// about you css

.aboutyou {
   .setupselectbox {
        .MuiSelect-select.MuiSelect-outlined {
            border-bottom: 1px solid #949d8c;
        }
    }
    // .dropdown .select-field{
    //     background: url(../../assets/images/sellerSide/dropdown.webp) 99%/1% no-repeat;
        
    // }
    .charity-text {
        text-align: left;
        font-size: 21px;
        letter-spacing: 0px;
        color: #949d8c;
        opacity: 1;
        font-family: "Asap";
        margin-bottom: 35px;
        margin-top: 30px;
    }

    .charity-para {
        text-align: left;
        font-size: 18px;
        letter-spacing: 0px;
        color: #757061;
        opacity: 1;
        font-family: "Asap";
        margin-bottom: 44px;
    }

    .mrgbottom {
        margin-bottom: 20px;
    }
}

//Almost there 

.almostthere {
    // .dropdown .select-field{
    //     background: url(../../assets/images/sellerSide/dropdown.webp) 99%/1.2% no-repeat;
        
    // }
    .checkbox-section {
        margin-top: 33px;
    }
.blackbtn{
    background: #2f2b23 !important;
}
    .terms-para {
        text-align: left;
        font-size: 21px;
        letter-spacing: 0px;
        color: #757061;
        opacity: 1;
        font-family: "Asap";
        margin-top: 20px;
    }

    .asterisk {
        color: red;
    }

    .name-heading,
    .captcha-code {
        color: #67757c;
        font-size: 20px;
        line-height: 22px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .btn-section {
        .input-field-loginside {
            width: 29.58%;
            margin: auto;
            text-align: center;
        }
    }

    .blackbtn {
        margin-top: 50px;
    }

    .selectbox .select-field {
        border-radius: 0 0;
        padding: 8px 8px 8px 0px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 92%;
        border-bottom: 1px solid #d8d4cf;
        width: 100% !important;
    }
}

@media (max-width: 1400px) {
    .prosellerregistration {

        .input-field-loginside,
        .select-field {
            font-size: 20px;
            line-height: 23px;
        }
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .prosellerregistration {
        .input-field-loginside {
            width: 100%;
            margin-bottom: 0.6vw;
            font-size: 1vw;
            line-height: 1.5vw;
            height: 2.5vw;
        }

        .setupselectbox .MuiSelect-select.MuiSelect-outlined {
            font-size: 1vw;
            line-height: 1.5vw;
        }

        .MuiSvgIcon-root {
            top: 0vw !important;
        }

        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 0.4vw 0.8vw !important;
            font-size: 0.8vw !important;
            font-weight: 500 !important;
            margin-bottom: 2vw !important;
            border-radius: 2.5vw !important;
            min-width: 6vw;
            min-height: 2.5vw;
        }

        .input-error-message {
            font-size: 0.8vw;
            color: #fc4b6c !important;
            display: block;
        }

        .aboutyou .charity-text,
        .label-trader {
            font-size: 1vw;
            line-height: 1.2vw;
        }

        .aboutyou .charity-para,
        .terms-para {
            font-size: 0.9vw;
            line-height: 1.2vw;
        }

        .checkbox-trader {
            margin-right: 31px;
            width: 1.3vw;
            height: 1.3vw;
        }

        .almostthere .name-heading,
        .almostthere .captcha-code {
            font-size: 1vw;
            line-height: 1.2vw;
        }
    }
}

@media only screen and (max-width: 600px) {
    .aboutyou {  
        .label-trader {
        text-align: left;
        font-size: 17px;
        line-height: 20px;
    }
    .checkbox-trader {
        margin-right: 11px;
        width: 22px;
        height: 26px;
    }
}

}