.seller-admin{
    padding: 15px 15px 15px 0px;
    // background-color: rgb(245, 245, 245);

  
    .product{
        font-size: 28px;
    font-weight: 500;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
    font-family:"asap";
    margin-bottom: 15px;
    }
    .table-section-seller{
        background-color: #ffff;
        margin: 10px 0px 20px 0px;
        height: auto;
        border-top: 3px solid #3c8dbc;
        padding: 20px 10px;
        overflow-x: auto;
    }
    .search-input {
    background: transparent;
    border-bottom: 2px solid #d8d4cf !important;
    border: none;
    border-radius: 0px !important;
    padding-left: 0px !important;
    text-align: left;
    .MuiSvgIcon-root, .css-96uuyl {
        display: none;
        }
        .MuiSvgIcon-root{
            display: none;
        }
         .MuiInputBase-input{
            padding-left: 0px !important;
        }
    }
  
    .css-96uuyl{
        margin-left: 0px;
    }
    .heading{
        color: #060707;
        white-space: initial;
        padding-bottom: 10px;
        font-weight: 100!important;
        font: -webkit-mini-control;
        opacity: .999;
        white-space: normal;
    }
    
    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
      }
      
      td, th {
        // border: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
    .product-name{
        color: rgba(102, 102, 102, 0.923);
        width: 100%;
        white-space: initial;
        font-weight: 300;
        opacity: 0.9;
        font-size: 14px;
        font-family:"Poppins", sans-serif;
        font-weight: 200;
        font: -webkit-control;
    }
    .tree-img{
        height: 80px;
        padding: 8px 0px;
        width: 110px;
    }
    .iconvisibility{
        height: 29px;
        width: 30px;
        border-radius: 4px;
        color: #333;
        text-align: center;
        background-color: #e7e7e7;
        border: 1px solid #ccc;
        margin-top: 5px;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 3px;
    
      }
      .iconvisibility:hover {
        border: 1px solid #444;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .seller-admin{
        padding: 0px 15px 15px 0px;
        .table-section-seller{
            overflow: auto;
            padding: 10px 10px;
        }
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .seller-admin{
        .table-section-seller{
        padding: 1vw;
        }
        .tree-img {
            height: 3vw;
        }
        .product {
            font-size: 1.5vw;
            line-height: 2vw;
        }
        .heading,.product-name {
            font-size: 0.9vw;
            line-height: 1.2vw;
        }
    }
}