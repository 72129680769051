.admin {
  .categories-container {
    padding: 15px;
    // background-color: rgb(245, 245, 245);
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
   
  
  }
.table-section{
  background-color: #ffff;
  margin: 10px 0px 20px 0px;
  height: auto;
  border-top: 3px solid #3c8dbc;
}
  .pagination {
    justify-content: start;
    margin-bottom: 20px;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 10px 0px 20px 30px;
    
  }
  .iconvisibility{
    height: 25px;
    width: 25px;
    border-radius: 4px 0px 0px 4px;
    color: #333;
    text-align: center;
    background-color: #e7e7e7;
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 3px;

  }
  .iconvisibility:hover {
    border: 1px solid #444;
}
.iconedit:hover {
  border: 1px solid #444;
}
.iconedit{
  height: 25px;
    width: 25px;
    border-radius: 0px;
    color: #333;
    text-align: center;
    background-color: #e7e7e7;
    border: 1px solid #ddd;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    
    margin-left: -4px;

}
  tr:nth-child(even) {
    background-color: #fff;
  }

  .heading {
    color: #060707;
    font-size: 14px;
    // white-space: initial;
    font-weight: 500;
  }

  .main-heading {
    font-size: 24px;
    font-weight: 500;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: left;
  }

  .row-heading {
    color: #666;
    font-size: 14px;
    width: 100%;
    white-space: initial;
    padding-right: 5px;
  }


}
@media (min-width:2000px) and (max-width:6000px){
  .admin{
    .main-heading {
      font-size: 1.4vw;
      line-height: 1.6vw;
      padding: 0.8vw 0;
    }
    .heading,.row-heading {
      font-size: 0.9vw;
      line-height: 1.2vw;
    }
  }
}