.remove-from {
  .input-heading {
    padding: 20px;
    text-align: center;
  }

  .upload-text {
    line-height: 46px;
    font-size: 39px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #333;
    text-align: center;
  }

  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
  }

  .heading {
    padding-bottom: 20px;
    width: 50%;
  }

  .pendingbtn  {
    background: #367fa9 !important;
    color: #fff;
    border-radius: 3px !important;
    height: 42px;
    font-size: 17px !important;
    line-height: 18px !important;
    text-transform: capitalize;
    padding: 6px 12px !important;
    margin-right: 10px;
  }
  button{
    color: #fff;
    border-radius: 3px !important;
    height: 42px;
    font-size: 17px !important;
    line-height: 18px !important;
    text-transform: capitalize;
    padding: 6px 12px !important;
    margin-right: 10px;
  }
}

@media (min-width:2000px) and (max-width:6000px) {
  .remove-from {
    .input-heading{
      font-size: 1.2vw;
      line-height: 1.5vw;
    }

    .heading {
      margin: 0 auto;
    }
.upload-text{
  font-size: 2vw;
  line-height: 2.5vw;
}
    .pendingbtn , button {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      line-height: 1.4vw !important;
      height: auto;
      margin-top: 1vw;
    }

    .input-heading {
      text-align: center;
    }
  }

}

@media (min-width:204px) and (max-width:600px) {
  .remove-from {
    .upload-text {
      line-height: 40px;
      font-size: 29px;
    }

    .input-heading {
      padding: 20px 0;
      text-align: center;
    }

    .heading {
      padding-bottom: 20px;
      width: 100%;
    }
  }
}