.search-input{
    height: 38px;
    text-align: center;
    border: 1px solid #d8d4cf;
    width: 219px !important;
    border-radius: 8px !important;
    input {
        width:100% !important;
    }
}


@media (min-width:280px) and (max-width:600px) {
    .search-input{
        width: 56vw !important;
    }
}

@media (min-width:2000px) and (max-width:6000px) {
    .search-input {
        margin-left: 0px;
        width: 12vw !important;
        height: 2.1vw;
        svg{
            font-size: 1.3vw;
        }
        input{
            padding: 0.4vw 0.4vw 0.4vw 2.4vw !important;
            font-size: 0.9vw;
        }
    }
}