.medialibrary {
  .btn-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
  }
  .product-text{
    margin: 0px;
    padding-right: 30px;
    font-family: "Asap";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #2f2b23;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 160px;
}
.product-text-section{
  display: flex;
  justify-content: space-between;
}
.copy-icon{
  display: none;
}
.darkbtn{
  text-transform: inherit !important;
  font-weight: 500 !important;
}
  .productscard:hover .icon-row {
    display: none;
  }

  .img-container {
    width: 33.33%;
    margin-bottom: 10px;

    input[type="radio"] {
      display: none;
    }
  }

  .img-row {
    min-width: 100px;
  }

  .productscard .product-text {
    width: 110px;
  }

  .product-price {
    display: none;
  }

  input[type="checkbox"][id^="myCheckbox"] {
    display: none;
  }

  .label-file {
    border: 1px solid #fff;
    // padding: 10px;
    display: block;
    position: relative;
    margin-bottom: 9px;
    cursor: pointer;
    // height: 152px;
  }

  .label-file:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    //border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  .label-file img {
    // height: 152px;
    //width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked+.label-file {
    border-color: #ddd;
  }

  :checked+.label-file:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
    z-index: 999;
    margin-left: 15px;
    margin-top: 18px;
  }

  :checked+.label-file img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
  }

  .lastbtn {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
    justify-content: flex-end;
    margin-right: 10px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .medialibrary {
    .img-container {
      width: 50%;
      margin-top: 14px;
    }

    .btn-section {
      display: block;
    }

    .input-field-outline {
      margin-bottom: 10px;
    }

    .productscard .product-text {
      width: 24vw;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .medialibrary {
    .productscard {
      margin-top: 1.2vw;
    }

    .sub-heading {
      font-size: 1vw;
      line-height: 1.2vw;
    }

    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .productscard .product-text {
      width: 8vw;
      font-size: 0.8vw;
    }
  }
}