.fileuploadsection {
    .attach-btn {
        background-color: grey;
        border-radius: 4px;
        opacity: 1;
        min-width: 250px;
        text-align: left;
        font-size: 20px;
        line-height: 22px;
        font-feature-settings: normal;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #333;
        opacity: 1;
        font-weight: normal;
        border: none;
        cursor: pointer;
        padding: 14px 23px;
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .file-section {
        position: relative
    }

    .input-type-file-upload {
        display: none;
    }

    .plusicon {
        background-color: #2e2b2b;
        border-radius: 50px;
        border: none;
        padding: 1px 14px 6px;
        font-size: 25px;
        margin-left: 57px;
        color: #FFFFFF;
        cursor: pointer;
    }
}

@media (min-width:1921px){
    .fileuploadsection{
        .plusicon{
        font-size: 1.2vw;
    padding: 0.1vw 0.8vw 0.5vw;
        }
    }
}