.publishupdate{
    .upload-text{
        font-size: 20px;
        line-height: 25px;
        font-family: "Asap";
        font-weight: bold;
        margin-bottom: 30px;
        color: #333;
    }
    .label-text{
        font-size: 18px;
        line-height:25px;
        font-family: "Asap";
        margin-bottom: 20px;
        color: #333;
        font-weight: 300;
    }
    .btn-section-refund{
        display: flex;
        justify-content: space-between;
        margin-top: 35px;

        .darkbtn, .lightbtn{
            text-transform: capitalize;
        }
        .twobtn{
            display: flex;
        }
    }
    .dropdown {
        margin-bottom: 20px;
    }
}