.SellerIdDetails {
  .view-heading {
    padding: 20px 25px;
    font-weight: 500;
    font-size: 19px;
    margin: 0px;
    color: #23282d;
    font-family: "Poppins", sans-serif;

  }

  .darkbtn {
    background-color: #e27730 !important;
    border-color: #b85a1b !important;
    height: 35px !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    font-weight: normal !important;
    letter-spacing: 0px;
    text-transform: initial !important;
  }

  .user-heading {
    display: flex;
    align-items: center;
    background-color: #ffff;
    margin: 5px 25px 30px 15px;
  }

  .user-id {
    margin: 0px;
    font-size: 18px;
    color: #23282d;
    padding: 8px 0px 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .icon {
    color: #e27730;
    padding: 6px 5px;

    .css-i4bv87-MuiSvgIcon-root {
      width: 28px;
      height: 36px;
    }

  }

  .start-icon {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .life-cycle {
    margin: 0px;
    padding: 12px 12px;
    background-color: #ebf3fb;

    h2 {
      margin: 0;
      font-size: 14px;
      color: #444;
      font-family: "Poppins", sans-serif;
    }
  }

  .opportunity {
    margin: 0px;
    padding: 12px 12px;
    font-weight: 300 !important;

    p {
      margin: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      font-family: "Poppins", sans-serif;
    }
  }


  .inner-container {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
  }

  .delete-container {
    padding: 2px 10px 10px;
    border: 1px solid #ddd;
    background: #f5f5f5;
  }

  .entry-text {
    font-size: 14px;
    color: #82878a;
    margin: 0px;
    font-weight: 600;
  }

  .key-id {
    // margin-bottom: 10px;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .keyicon {
    color: #82878a;
    margin-right: 3px;
    margin-right: 10px;
    font-size: 24px;
    // padding-top: 5px;
  }

  .entry-id {
    font-size: 14px;
    color: #444;
    margin: 0px;
    font-weight: 200;
    font-family: 'Asap';
  }
  .entry-id-data{
    font-size: 14px;
    color: #444;
    margin: 0px;
    font-weight: 600;
    font-family: 'Asap';
    padding-left: 0.2vw;
  }

  .delete-entry {
    background-color: transparent;
    color: red;
    border: none;
    font-size: 12px;
    letter-spacing: 0px !important;
    font-weight: 500;
  }

  .width70grid {
    background: #FFF;
    margin: 0px 0 30px 15px;
    border: 1px solid #ddd;
    // padding:10px;
  }

  // .mrgbottom {
  //   margin-bottom: 20px;

  // }
}

@media (min-width:280px) and (max-width:600px){
  .SellerIdDetails{
    .width70grid{
      margin: 0;
    }
    .user-heading{
      margin: 15px 0px 30px 0px;
      justify-content: space-between;
      padding-right:12px;
    }
    .view-heading {
      font-size: 16px;
      padding: 18px;
    }
    .user-id {
      font-size: 18px;
      
  }
  .life-cycle {
    font-size: 10px;
}
.opportunity {
  font-size: 12px;
}.entry-text {
font-size:14px;
line-height: 16px;

}
.entry-id {
font-size: 12px;
line-height: 16px;
}
  }
}

@media (min-width:2000px) and (max-width:6000px){
  .SellerIdDetails{
    .view-heading {
      font-size: 1.2vw;
      padding: 1vw;
    }
    .key-id{
      display: block;
    }
    .lightbtn,
        .darkbtn {
            border-radius: 0.5vw !important;
            padding: 1vw !important;
            font-size: 0.8vw !important;
        }
        .user-id {
          font-size: 1vw;
          padding: 0.7vw 0px 0.7vw;
      }
      .life-cycle h2 {
        font-size: 0.9vw;
        padding: 0.5vw;
    }
    .opportunity p {
      font-size: 0.8vw;
      padding: 0.5vw;
  }.entry-text {
    font-size: 0.9vw;
    line-height: 1.2vw;
    margin-bottom: 0.8vw;
  }
  .entry-id, .entry-id-data {
    font-size: 0.8vw;
    line-height: 1.2vw;
  }
  .key-id {
    margin-bottom: 0.5vw;
  }
  .inner-container{
    padding: 0.8vw;
  }
  // .delete-container{
  //   padding: 0.8vw;
  // }
  .delete-entry {
    padding: 0.5vw 0px;
    line-height: 1.2vw;
    font-size: 0.8vw;
}
  }
  
}