.main-heading40 {
    font-family: "Asap";
    font-style: normal;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 8px;
    opacity: 0.9;

    // .trending-heading {
    //     letter-spacing: 0px;
    //     color: #0a0a0a;
    //     margin-bottom: 1.04vw;
    // }
}

.sub-heading{
    font-family: "Asap";
    font-style: normal;
    font-size: 17px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #2f2b23;
    margin-bottom: 16px;
}