.dataside{
  background: rgb(250, 249, 249);
  
}
.top-picker{
  background-color: #d3cec0;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  font-family: Asap;
  font-style: normal;
  padding: 10px;
  text-transform: uppercase;
  min-height: 54px;
}
.sellersidebar{
  background: rgb(250, 249, 249);
  height: 100%;

  
  .logogoethical{
    width: 72%;
    padding: 0px 20px;
     margin-top: 16px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    object-fit: cover;
  }  
  a:hover, a:active, a:focus {
    text-decoration: none;
}
.sidebar-menu-name {
    color: #2f2b23;
    font-size: 16px;
    font-family: "Asap";
    font-weight: normal;
    font-style: normal;
    line-height: 130%;
  }
  .menu-name {
    margin-bottom: 0px !important;
    height: 42px;
  }
  
  .menu-name:hover {
    .icon-sidebar {
      color: #2F2B23 !important;
    }
  
    .sidebar-menu-name {
      color: #969c8d;
      font-size: 16px;
    }
  }
  
  .menu-name:hover .sidebar-menu-name {
    color: #969c8d;
    font-size: 16px;
  }
  
  
  .item2{
    padding: 10px 10px 10px  20px; 
    display: flex;
    border-radius: 8px;
    margin: 7px 16px 14px;
    align-items: center;
    cursor: pointer;
  }
  .item2:focus{
    background-color: #E8E5E3;
    border-radius: 8px;
   }
   .item2:active{
    background-color: #E8E5E3;
    border-radius: 8px;
   }
   .item2:hover{
    background-color: #E8E5E3;
    border-radius: 8px;
   }
  
  
  .new-sidebar{
    height: 16px;
    width: 16px;
    margin-right: 16px;
  }
  

  
}
.MuiDrawer-root{
  .MuiListItemIcon-root{
    min-width: 40px;
  }
  img.new-sidebar {
    width: 18px;
}
.MuiListItemText-root span{
    color: #2f2b23;
    font-size: 16px;
}
}
@media (min-width:280px) and (max-width:600px) {

    .mobileviewset{
      display: flex;
        align-items: center;
        justify-content: space-between;
    padding: 12px;
    .MuiSvgIcon-root {
      color: #000 !important;
    }
    .logogoethical{
      width: 45% !important;
    }
  }
}
@media (min-width:601px) and (max-width:919px) {

  .mobileviewset{
    display: flex;
      align-items: center;
      justify-content: space-between;
  padding: 12px;
  .MuiSvgIcon-root {
    color: #000 !important;
  }
  .logogoethical{
    width: 25% !important;
    margin-bottom: 10px;
  }
}
}

@media (min-width:2000px) and (max-width:6000px) {
  .sellersidebar{
      .sidebar-menu-name {
        font-size: 1.2VW;
        margin-left: 0.8vw;
        line-height: 1.6;
    }
   
    .new-sidebar {
      height: 1.2vw;
      width: 1.2vw;
      margin-right: 0.8vw;
  }
  .item2 {
    padding: 0.6vw 0.6vw 0.6vw 2vw;
    border-radius: 0.5vw;
    margin: 0.4vw 0.8vw;
}
  }
  .top-picker{
    font-size: 0.88vw;
    line-height: 1.2vw;
    padding: 0.5vw;
    min-height: 2.5vw;
  }
}