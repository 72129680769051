.empty-img {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 45px;
    min-height: 155px;
}

.chart-text {
    margin: 0px;
    font-weight: normal;
    font-family: "Asap";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #757061;
    padding: 0 29%;
    // margin-top: 8px;
}
@media (min-width:2000px) and (max-width:6000px) {
    .empty-img{
        img{
            width: 5vw;
        }
    }
    .chart-text{
        font-size: 0.8vw;
        line-height: 1.2vw;
    }
}